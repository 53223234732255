import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Customers from './components/Customers';
import AllCustomers from './components/AllCustomers';
import Transactions from './components/Transactions'; // Import the Transactions component

function App() {
  return (
    <Router>
      <div className='mx-3'>
        <Navbar />
        <Routes>
          <Route path="/" element={<Customers />} />
          <Route path="/customers" element={<AllCustomers />} />
          <Route path="/transactions" element={<Transactions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
