import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Helper function to add a transaction
export async function addTrans(entries) {
  try {
    const res = await fetch("https://moi-app.cleverapps.io/transaction", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(entries),
    });

    if (!res.ok) {
      const error = await res.json();
      throw new Error(error.message || "Failed to add transaction");
    }

    const data = await res.json();
    return data.message;
  } catch (error) {
    console.error("Error adding transaction:", error);
    throw error;
  }
}

// Component to add a transaction
function AddTransaction({ show, close, id, firstName }) {
  const [transdata, setTransdata] = useState({
    customer_id: id,
    amount: "",
    date: "",
    note: "",
    type: "EVENT",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setTransdata((prev) => ({ ...prev, [name]: value }));
  };

  const validateFields = () => {
    const { amount, date } = transdata;
    if (!amount || !date) {
      alert("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateFields()) return;

    try {
      setLoading(true);
      const message = await addTrans(transdata);
      alert(message);
      
      close(); // Close modal after successful submission
      window.location.reload()
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={close} onClick={(e)=>{e.stopPropagation()}} >
        <Modal.Header closeButton>
          <Modal.Title>Add Transaction - { firstName }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              name="date"
              id="date"
              className="form-control mb-3"
              value={transdata.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              name="amount"
              id="amount"
              className="form-control mb-3"
              value={transdata.amount}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="note">Note</label>
            <textarea
              name="note"
              id="note"
              rows="1"
              className="form-control mb-3"
              value={transdata.note}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
          <div className="form-group d-none">
            <label htmlFor="type">Type</label>
            <input
              type="text"
              name="type"
              id="type"
              className="form-control"
              value={transdata.type}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTransaction;
