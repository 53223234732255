import React,{useState} from 'react'
import { CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCollapse, CNavbarNav, CNavLink,  } from "@coreui/react";
import { Link } from 'react-router-dom';
const Navbar = () => {
    const [navToggle, setNavToggle] = useState(false)
    return (
      <>
        <CNavbar expand="lg" className="bg-body-tertiary">
          <CContainer fluid>
            <CNavbarBrand href="#">CSC MOI</CNavbarBrand>
            <CNavbarToggler
              aria-label="Toggle navigation"
              aria-expanded={navToggle}
              onClick={() => setNavToggle(!navToggle)}
            />
            <CCollapse className="navbar-collapse" visible={navToggle   }>
              <CNavbarNav as="nav">
                <CNavLink active>
                    <Link to="/" active style={{ textDecoration:"none", color:"black" }}>Home</Link>
                </CNavLink>
                <CNavLink>
                    <Link to="/customers" style={{ textDecoration:"none", color:"black" }}>Customers</Link>
                </CNavLink>
                <CNavLink>
                    <Link to="/transactions" style={{ textDecoration:"none", color:"black" }} >Transactions</Link>
                </CNavLink>
              </CNavbarNav>
            </CCollapse>
          </CContainer>
        </CNavbar>
      </>
    )
}

export default Navbar