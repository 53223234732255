import React, { useState } from "react";
import { CButton, CCollapse, CCardBody } from "@coreui/react";
import { CSmartTable } from "@coreui/react-pro";
import AddTransaction from "./AddTransaction";
import { BiShow } from "react-icons/bi";


// Fetch transaction data
export async function fetchTransaction(id) {
  try {
    const res = await fetch("https://moi-app.cleverapps.io/transaction/" + id);
    const tr = await res.json();
    return tr;
  } catch (e) {
    console.error(e);
  }
}

// Delete a transaction
export async function deleteTransaction(id) {
  try {
    const res = await fetch("https://moi-app.cleverapps.io/transaction/" + id, {
      method: "DELETE",
    });
    const tr = await res.json();
    return tr.message;
  } catch (error) {
    console.error(error);
  }
}

const Datatable = ({ columns, data }) => {
  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState({});
  const [modalVisibility, setModalVisibility] = useState({});

  // Toggle modal visibility for adding a transaction
  const toggleModal = (id) => {
    setModalVisibility((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Delete a transaction
  const deleteTrans = async (id) => {
    try {
      const conf = window.confirm("Do you want to delete?");
      if (!conf) return; // Exit if the user cancels

      // Call deleteTransaction to perform the deletion
      const response = await deleteTransaction(id);
      alert(response);

      // Re-fetch all transactions after deletion
      const updatedTransactions = await fetchTransaction(id);
      console.log(updatedTransactions);
      setTransactions((prev) => ({
        ...prev,
        [id]: updatedTransactions,
      }));
    } catch (error) {
      console.error("Error deleting transaction:", error);
      alert("Failed to delete the transaction. Please try again.");
    }
  };

  // Toggle details for showing/hiding transaction details
  const toggleDetails = async (index) => {
    
    const position = details.indexOf(index);
    let newDetails = [...details];

    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [index];
      // Fetch and update the transaction details for this index
      const details = await fetchTransaction(index);
      setTransactions((prev) => ({
        ...prev,
        [index]: details,
      }));
    }
    setDetails(newDetails);
  };

  // Scoped columns for displaying transaction amount and show details button
  const scopedColumns = {
    amount: (item) => {
      const color = item.amount >= 0 ? "green" : "red";
      return (
        <td className="py-2" style={{ color, fontSize: "1.2rem" }}>
          {item.amount}
        </td>
      );
    },
    show_details: (item) => {
      return (
        <td className="py-2">
          <span style={{ display: "flex" }}>
          <CButton
              color="primary"
              shape="square"
              size="sm"
              style={{ marginLeft: "5px" }}
              onClick={(item) => {toggleDetails(item.id);}}
            >
              <BiShow/>
            </CButton>
            <CButton
              color="success"
              shape="square"
              size="sm"
              style={{ marginLeft: "5px" }}
              onClick={(event) => { event.stopPropagation(); toggleModal(item.id)}}
            >
              +
            </CButton>
          </span>
          {modalVisibility[item.id] && (
            <AddTransaction
              id={item.id}
              firstName={item.first_name}
              show={modalVisibility[item.id]}
              close={() => { toggleModal(item.id)}}
            />
          )}
        </td>
      );
    },
    details: (item) => {
      const transaction_columns = [
        { key: "id", label: "#" },
        { key: "type", label: "Type" },
        { key: "date", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "note", label: "Notes" },
        { key: "created_date", label: "Created Date" },
        { key: "action", label: "Action" },
      ];

      const transactionScopedColumns = {
        action: (item) => (
          <td className="py-2">
            <CButton
              color="danger"
              variant="outline"
              shape="square"
              size="sm"
              onClick={() => deleteTrans(item.id)}
            >
              Delete
            </CButton>
          </td>
        ),
      };

      return (
        <CCollapse visible={details.includes(item.id)}>
          <CCardBody className="p-3">
            <CSmartTable
              columns={transaction_columns}
              items={transactions[item.id] || []}
              scopedColumns={transactionScopedColumns}
              tableProps={{
                style: { border: "2px solid blue" },
                responsive: true,
                striped: false,
                hover: true,
              }}
              tableBodyProps={{
                className: "align-middle",
                style: { textAlign: "center" },
              }}
            />
          </CCardBody>
        </CCollapse>
      );
    },
  };

  return (
    <CSmartTable
      columns={columns}
      items={data}
      scopedColumns={scopedColumns}
      pagination
      itemsPerPage={10}
      itemsPerPageSelect
      columnFilter
      columnSorter
      clickableRows
      cleaner
      tableProps={{
        className: "add-this-class",
        responsive: true,
        striped: true,
        hover: true,
      }}
      onRowClick={(item) => {toggleDetails(item.id); console.log("Rowclicked "+item.id)}}
      tableBodyProps={{
        className: "align-middle",
        style: { textAlign: "center" },
      }}
    />
  );
};

export default Datatable;
