import React,{ useEffect, useState } from 'react';
import Datatable from './Layout/Datatable';
export async function fetchCustomers() {
  try {
    const res = await fetch(
      "https://moi-app.cleverapps.io/customers"
    );
    const cust = await res.json();
    return cust;
  }  catch (e) {
    console.error(e);
  }
}

const Customers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = async () => {
      const href = await fetchCustomers();
      setData(href);
    };
    data();
  }, []);
  const columns = [

    { key: 'page_no', label: 'Page No', _style: { width: '5%', textAlign:'center' } },
    { key: 'village', label: 'Village', _style: { width: '20%', textAlign:'center' } },
    { key: 'first_name', label: 'Name', _style: { width: '20%', textAlign:'center' }},
    { key: 'last_name', label: 'Initial', _style: { width: '8%', textAlign:'center' }},
    { key: 'note', label: 'Note', _style: { width: '15%', textAlign:'center' } },
    { key: 'amount', label: 'Amount', _style: { width: '10%', textAlign:'center' },filter: false },
    { key: 'show_details', label: '', _style: { width: '1%' }, filter: false, sorter: false}
  ];

  return (
    <div data-coreui-theme="dark">
      <Datatable columns={columns} data={data} />
    </div>
  );
};

export default Customers;
